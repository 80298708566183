import { Suspense, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Spinner } from "components"
import { MantineProvider } from "@mantine/core"

import Navbar from "./Navbar"

import "@mantine/core/styles.css"
import "./layout.scss"

const theme = {}

function Layout() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <MantineProvider theme={theme}>
      <div className="adv-layout">
        <Navbar />
        <div className="adv-page-wrapper">
          <main className="container">
            <Suspense fallback={<Spinner />}>
              <Outlet />
            </Suspense>
          </main>
        </div>
      </div>
    </MantineProvider>
  )
}

export default Layout
