function CoinMarketCard() {
  return (
    <div className="coin-market__card">
      <img src="" alt="" className="sk" style={{ minHeight: "180px" }} />
      <div className="coin-market__card-bottom">
        <h3 className="coin-market__card-title sk" style={{ marginBottom: "5px" }}>
          {" "}
        </h3>
        <h3 className="coin-market__card-title sk" style={{ marginBottom: "5px" }}>
          {" "}
        </h3>
        <h3 className="coin-market__card-title sk" style={{ marginBottom: "20px" }}>
          {" "}
        </h3>

        <p className="coin-market__card-price mt-10 sk" style={{ maxWidth: "100px" }}>
          {" "}
        </p>

        <div className="center">
          <button type="button" className="btn btn-primary mt-20">
            Xarid qilish
          </button>
        </div>
      </div>
    </div>
  )
}

export default CoinMarketCard
