/* eslint-disable react/prop-types */
import { Icons } from "components"

import { useNavigate } from "react-router-dom"

import "./styles.scss"

function GoBackHeader({ title, hasBack }) {
  const navigate = useNavigate()

  return (
    <div className="goback-header__container">
      <div className="goback-header">
        {hasBack && (
          <div className="goback-header__icon" onClick={() => navigate(-1)}>
            <Icons.ArrowLeft />
          </div>
        )}
        <div className="btn"> {title} </div>
        <div> </div>
      </div>
    </div>
  )
}

export default GoBackHeader
