/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */

import ReactSelect from "react-select"
import propTypes from "prop-types"
import { useFormContext } from "react-hook-form"

import cn from "classnames"

import "./select.scss"
import { find, get } from "lodash"
import { useEffect, useRef, useState } from "react"

const div = document.createElement("div")

function Select({ name, label, required, options, defaultValue, onChange, zIndex, ...props }) {
  const ref = useRef()
  const [render, setRender] = useState(0)
  const { register, setValue, watch, getValues, formState } = useFormContext()
  watch(name)
  const { errors } = formState
  register(name)

  const scrollToView = () => {
    const menuListRef = document.querySelector(`.select-${name} .select__menu-list`) || div
    const optionRefs = document.querySelectorAll(`.select-${name} .select__option`)
    if (optionRefs.length) {
      find(optionRefs, (ell) => {
        const optionRef = ell
        if (optionRef.innerHTML.includes(get(getValues(name), "label"))) {
          optionRef.scrollIntoView()
          return true
        }
        return false
      })
    }
    if (menuListRef) {
      const active = menuListRef.querySelector(".select__option--is-selected") || div
      active.scrollIntoView()
    }
  }

  useEffect(() => {
    scrollToView()
  }, [render])

  return (
    <div className="form-group" ref={ref} style={{ position: "relative", zIndex }}>
      {label && (
        <label className={`form-label ${required ? "required" : ""}`} htmlFor="select">
          {label}
        </label>
      )}
      <ReactSelect
        key={JSON.stringify(getValues()[name])}
        className={`select select-${name}`}
        classNamePrefix="select"
        onChange={(option) => {
          onChange(option)
          setValue(name, option)
        }}
        value={getValues()[name]}
        defaultValue={getValues()[name]}
        options={options}
        onMenuOpen={() => setRender(render + 1)}
        placeholder="Tanlang..."
        {...props}
      />
      <p className={cn("form__error", { visiable: errors[name] })}>
        {get(errors, `${name}.message`)}
      </p>
    </div>
  )
}

export default Select

Select.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  required: propTypes.bool,
  options: propTypes.array,
  onChange: propTypes.func,
  defaultValue: propTypes.shape({
    value: propTypes.any,
    label: propTypes.string,
  }),
}

Select.defaultProps = {
  label: "",
  required: false,
  options: [],
  defaultValue: {},
  onChange: () => {},
}
