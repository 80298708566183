import ProductList from "Front/Components/ProductList"
import { useSearchParams } from "react-router-dom"

import "./styles.scss"
import { useRequest } from "hooks"
import Breadcrumb from "Front/Components/Breadcrumb"
import { get } from "lodash"
import { useState } from "react"

function ProductFilter() {
  const [searchParams] = useSearchParams()
  const [pages, setPages] = useState({})
  const [page, setPage] = useState(1)

  const allProducts = useRequest({
    url: "products",
    route: `stores/${process.env.REACT_APP_STORE_ID}`,
    urlSearchParams: {
      page: page || 1,
      category_id: searchParams.get("category_id") || undefined,
      search: searchParams.get("search") || undefined,
    },
    onSuccess: (res) => {
      setPages((pv) => ({ ...pv, [page || 1]: get(res, "data") }))
    },
  })

  const list = Object.values(pages).reduce((pv, cr) => [...pv, ...cr], [])

  const title =
    searchParams.get("category_title") || searchParams.get("search") || "Barcha Maxsulotlar"

  return (
    <div className="page-product-filter">
      <div className="container mt-20 mb-20">
        <Breadcrumb text={title} />
      </div>
      <div className="center mb-20">
        <p className="c-title">{title}</p>
        <p className="amount">{get(allProducts.data, "meta.total")} ta maxsulot</p>
      </div>
      <ProductList
        data={{
          data: list,
          meta: get(allProducts.data, "meta"),
        }}
        isLoading={allProducts.isLoading}
        setPage={setPage}
      />
    </div>
  )
}

export default ProductFilter
