function List() {
  return (
    <li>
      <div className="avatar sk"> </div>
      <div className="bb">
        <div>
          <div className="info">
            <p className="name sk" style={{ maxWidth: "200px", marginBottom: "5px" }}>
              {" "}
            </p>
            <p className="position sk" style={{ maxWidth: "150px" }}>
              {" "}
            </p>
          </div>
        </div>
        <div className="right" style={{ minWidth: "100px" }}>
          <p className="sk" style={{ maxWidth: "150px" }}>
            {" "}
          </p>
        </div>
      </div>
    </li>
  )
}

export default List
