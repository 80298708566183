import { Navigate, Outlet } from "react-router-dom"
import Layout from "Front/Layout"
import Home from "Front/Pages/Home"
import ProductView from "Front/Pages/ProductView"
import ProductFilter from "Front/Pages/ProductFilter"
import Signin2 from "Advertiser/Pages/Auth/Signin2"
import OrderSuccess from "Front/Pages/OrderSuccess"
import Pages from "./Pages"

const GuestRoutes = ({ isAuth, isAdminAuth, isFetching }) => [
  {
    path: "",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/product/:id",
        element: <ProductView />,
      },
      {
        path: "/oqim/:streamId",
        element: <ProductView />,
      },
      {
        path: "/products",
        element: <ProductFilter />,
      },
      {
        path: "/thanks",
        element: <OrderSuccess />,
      },
    ],
  },
  !isAdminAuth && !isFetching
    ? {
        path: "dashboard",
        element: <Outlet />,
        children: [
          {
            path: "signin",
            element: <Signin2 />,
          },
          {
            index: true,
            element: <Navigate to="/dashboard/signin" />,
          },
        ],
      }
    : {},
  isAuth && !isAdminAuth
    ? {
        path: "dashboard",
        element: <Outlet />,
        children: [
          {
            path: "restricted",
            element: <Pages.Forbidden />,
          },
          {
            index: true,
            element: <Navigate to="/dashboard/signin" />,
          },
        ],
      }
    : {},
  !isAuth
    ? {
        path: "admin",
        element: <Outlet />,
        children: [
          {
            path: "signin",
            element: <Signin2 />,
          },
          {
            index: true,
            element: <Navigate to="/admin/signin" />,
          },
        ],
      }
    : {},
  {
    path: "*",
    element: <> </>,
  },
]

export default GuestRoutes
