import Banners from "Front/Components/Banners"
import CategoryCards from "Front/Components/CategoryCards"
import ProductList from "Front/Components/ProductList"
import { useRequest } from "hooks"
import { get } from "lodash"
import { useState } from "react"

function Home() {
  const [pages, setPages] = useState({})
  const [page, setPage] = useState(1)

  const allProducts = useRequest({
    url: "products",
    route: `stores/${process.env.REACT_APP_STORE_ID}`,
    urlSearchParams: {
      page: page || 1,
      status: "active",
    },
    onSuccess: (res) => {
      setPages((pv) => ({ ...pv, [page || 1]: get(res, "data") }))
    },
  })

  const list = Object.values(pages).reduce((pv, cr) => [...pv, ...cr], [])

  return (
    <div className="page-home">
      <Banners />
      <CategoryCards />
      <div className="pt-30">
        <ProductList
          data={{
            data: list,
            meta: get(allProducts.data, "meta"),
          }}
          isLoading={allProducts.isLoading}
          setPage={setPage}
        />
      </div>
    </div>
  )
}

export default Home
