import { QueryClient } from "@tanstack/react-query"
import storage from "services/Storage"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
})

export const globalState = {
  language: "en",
  user: storage.get("user"),
  isAuth: !!storage.get("token"),
  isAdminAuth: false,
}

export const freeDeliveryStartsFrom = 30000

export const defaultMeta = {
  currentPage: 1,
  pageCount: 1,
  perPage: 1,
  totalCount: 1,
}
