import { freeDeliveryStartsFrom } from "config"

import { ReactComponent as Coin } from "./coin.svg"

function FreeDeliveryCoin({ fee }) {
  if (fee < freeDeliveryStartsFrom) return ""
  return (
    <Coin
      style={{ width: "18px", height: "18px", marginRight: "5px", transform: "translateY(2px)" }}
    />
  )
}

export default FreeDeliveryCoin
