/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import { Icons } from "components"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { helpers } from "services"

import "./styles.scss"
import useGetme from "hooks/useGetme"
import { useRequest } from "hooks"
import { get } from "lodash"
import { useQueryClient } from "@tanstack/react-query"

const statusBgColors = {
  delivered: "#0ACF83",
  accepted: "#0ACF83",
  sent: "#0ACF83",
  cancelled: "#F41F6C",
  hold: "#1ABCFE",
  pending: "#1ABCFE",
  spam: "#20202480",
  archived: "#F41F6C",
}

function Dashboard() {
  const queryClient = useQueryClient()
  const { hold_balance } = useGetme()

  const orderStats = useRequest({
    url: "charts/order-count-by-status",
    ttl: 5,
  })

  const sortedStats = (get(orderStats.data, "data") || []).sort((a, b) => a.count - b.count)

  const balance = helpers.getAccountFromAccounts({
    attribute: "balance",
  })

  useEffect(() => {
    queryClient.refetchQueries(["profile"])
  }, [])

  return (
    <div className="dashboard">
      <div className="container">
        <div className="row box g-20">
          <div className="card-balance col f-c-sb">
            <div className="content">
              <p className="key">Hisobingizda</p>
              <p className="value c-primary">{balance} so‘m</p>
            </div>
            <Link to="/admin/payment">
              <Icons.Withdraw />
            </Link>
          </div>
          <div className="card-balance col f-c-sb">
            <div className="content">
              <p className="key">Taxminiy Balans</p>
              <p className="value c-light">{helpers.toReadable(hold_balance)} so‘m</p>
            </div>
          </div>
        </div>
        <div className="statistics">
          {sortedStats.map(({ status_label: label, count, status }, i) => (
            <Link to={`/admin/request?status=${status}`} className="statistics-card" key={i}>
              <div className="statistics-card__content">
                <p className="title">{label}</p>
                <p className="count">{count}</p>
              </div>
              <span className="bg-shape" style={{ "--shape-color": get(statusBgColors, status) }}>
                {" "}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
