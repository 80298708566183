import { NavLink, useSearchParams } from "react-router-dom"
import useGetme from "hooks/useGetme"
import { useAtom } from "jotai"
import { atomNav } from "services/jotai"

import "./sidebar.scss"

function Sidebar() {
  const [activeNav] = useAtom(atomNav)
  const [searchParams] = useSearchParams()
  const { role } = useGetme()

  const activeSidebar = activeNav.children

  return (
    <aside className="sidebar">
      <ul className="sidebar__items">
        {activeSidebar.map(
          ({ to, label, icon, end, onClick = () => {}, preventSearchParams, roles }) =>
            (roles.includes(role) || roles.includes("*")) && (
              <li key={to} onClick={() => onClick({ to, from: window.location.pathname })}>
                <NavLink
                  to={preventSearchParams ? `${to}?${searchParams.toString()}` : to}
                  className={() =>
                    window.location.pathname === to ? "active sidebar__item" : "sidebar__item"
                  }
                  end={end}
                >
                  <span className="icon">{icon}</span>
                  <p className="sidebar__item-text">{label}</p>
                </NavLink>
              </li>
            ),
        )}
      </ul>
    </aside>
  )
}

export default Sidebar
