import { useRequest } from "hooks"
import PropTypes from "prop-types"

export default function Main({ children, ...props }) {
  const query = useRequest({ ...props })
  return <>{children(query)}</>
}

Main.propTypes = {
  children: PropTypes.func.isRequired,
}