import { CustomTable, Fields } from "components"
import { useRequest } from "hooks"
import { get } from "lodash"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import Paginate from "Advertiser/components/Paginate"
import GoBackHeader from "components/GoBackHeader"
import useGlobalState from "hooks/useGlobalState"

import "./styles.scss"

function Statistic() {
  const [state] = useGlobalState()
  const [searchParams] = useSearchParams()
  const [page, setPage] = useState(0)

  const urlSearchParams = {
    page: page + 1,
  }
  if (searchParams.get("search")) urlSearchParams.search = searchParams.get("search")

  const statsRes = useRequest({
    url: "streams-statistics-table",
    route: `users/${get(state, "user.id")}`,
    urlSearchParams,
    ttl: 5,
    select: (data) => {
      const arr = get(data, "data.data") || []
      const totals = arr.reduce(
        (pv, cr) => ({
          ...pv,
          visits: pv.visits + cr.visits,
          orders_new: pv.orders_new + cr.orders_new,
          orders_accepted: pv.orders_accepted + cr.orders_accepted,
          orders_sent: pv.orders_sent + cr.orders_sent,
          orders_delivered: pv.orders_delivered + cr.orders_delivered,
          orders_pending: pv.orders_pending + cr.orders_pending,
          orders_spam: pv.orders_spam + cr.orders_spam,
          orders_cancelled: pv.orders_cancelled + cr.orders_cancelled,
          orders_hold: pv.orders_hold + cr.orders_hold,
          orders_archived: pv.orders_archived + cr.orders_archived,
        }),
        {
          name: "JAMI",
          visits: 0,
          orders_new: 0,
          orders_accepted: 0,
          orders_sent: 0,
          orders_delivered: 0,
          orders_pending: 0,
          orders_spam: 0,
          orders_cancelled: 0,
          orders_hold: 0,
          orders_archived: 0,
        },
      )
      return [...arr, totals]
    },
  })

  return (
    <div className="statistic">
      <GoBackHeader title="Statistika" />
      <Fields.Search name="search" />
      <CustomTable
        data={statsRes.data}
        isLoading={statsRes.isLoading}
        className="adv-table"
        columns={[
          {
            title: "Oqim",
            dataIndex: "name",
            hasSort: false,
          },
          {
            title: "Tashrif",
            dataIndex: "visits",
            hasSort: false,
          },
          {
            title: "Yangi",
            dataIndex: "orders_new",
            hasSort: false,
          },
          {
            title: "Qabul qilindi",
            dataIndex: "orders_accepted",
            hasSort: false,
          },
          {
            title: "Yetkazilmoqda",
            dataIndex: "orders_sent",
            hasSort: false,
          },
          {
            title: "Yetqazib berildi",
            dataIndex: "orders_delivered",
            hasSort: false,
          },
          {
            title: "Qayta qo'ngiroq",
            dataIndex: "orders_pending",
            hasSort: false,
          },
          {
            title: "Spam",
            dataIndex: "orders_spam",
            hasSort: false,
          },
          {
            title: "Qaytib keldi",
            dataIndex: "orders_cancelled",
            hasSort: false,
          },
          {
            title: "HOLD",
            dataIndex: "orders_hold",
            hasSort: false,
          },
          {
            title: "Arxivlandi",
            dataIndex: "orders_archived",
            hasSort: false,
          },
        ]}
      />
      <Paginate
        pageCount={get(statsRes.data, "meta.last_page") || 1}
        setPage={(e) => setPage(e.selected)}
      />
    </div>
  )
}

export default Statistic
