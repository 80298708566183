import { get } from "lodash"
import { useState } from "react"
import { Badge } from "@mantine/core"
import { Tooltip } from "react-tippy"
import { useSearchParams } from "react-router-dom"

import { CustomTable, GoBackHeader, Icons, Modal, ModalContents } from "components"
import { queryClient } from "config"
import { notification, time } from "services"
import { useMutate, useRequest } from "hooks"
import Categories from "Advertiser/components/Categories"
import Search from "Advertiser/components/fields/Search"

import "./styles.scss"
import Paginate from "Advertiser/components/Paginate"
import useGlobalState from "hooks/useGlobalState"

const statuses = [
  {
    text: "Barchasi",
    value: " ",
  },
  {
    text: "Yangi",
    value: "new",
  },
  {
    text: "Dostavkaga tayyor",
    value: "accepted",
  },
  {
    text: "Yetqazilmoqda",
    value: "sent",
  },
  {
    text: "Yetkazib berildi",
    value: "delivered",
  },
  {
    text: "Qaytib keldi",
    value: "cancelled",
  },
  {
    text: "Keyin oladi",
    value: "pending",
  },
  {
    text: "Arxiv",
    value: "archived",
  },
  {
    text: "Spam",
    value: "spam",
  },
  {
    text: "Maxsulot kutilmoqda",
    value: "hold",
  },
]

function Request() {
  const [state] = useGlobalState()
  const [searchParams] = useSearchParams()
  const query = searchParams.get("search")
  const [orderAttaching, setOrderAttaching] = useState()

  const urlSearchParams = {
    page: searchParams.get("page") || 1,
  }
  if (query) urlSearchParams.search = query
  if (searchParams.get("status") && searchParams.get("status") !== " ")
    urlSearchParams.status = searchParams.get("status")

  const orders = useRequest({
    url: "advertiser-orders",
    route: `users/${get(state, "user.id")}`,
    urlSearchParams,
  })

  const list = get(orders, "data.data") || []

  const accept = useMutate({
    url: "orders",
    enabled: false,
    onSuccess: (data) => {
      notification.success()
      orders.refetch()
      setOrderAttaching()
      queryClient.setQueryData(["orders", urlSearchParams], (old) => {
        const arr = get(old, "data.data") || []
        if (arr)
          old.data.data = old.data.data.map((item) =>
            get(item, "id") === get(data, "id") ? data : item,
          )
        return old
      })
    },
  })

  const acceptOrder = (id) => {
    if (!accept.isLoading && !orders.isLoading) accept.mutate({ extraUrl: `/${id}/assign-order` })
  }

  return (
    <div className="request">
      <GoBackHeader title="So'rovlar" />
      <Search />
      <div className="mt-3">
        <Categories items={statuses} />
      </div>
      <CustomTable
        data={list}
        isLoading={orders.isLoading}
        className="adv-table"
        rowClassName={(row) => get(row, "operator_id") && "attached"}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            hasSort: false,
          },
          {
            title: "Oqim",
            dataIndex: "stream_name",
            hasSort: false,
          },
          {
            title: <p>Buyurtmachi / Manzil</p>,
            hasSort: false,
            dataIndex: "full_address",
            render: (col, row) => (
              <>
                <div className="cur-pointer">
                  <p>{get(row, "client_full_name")} - </p>
                  <p>{get(row, "customer_phone")}</p>
                </div>
                <p>{col}</p>
              </>
            ),
          },
          {
            title: "Holat",
            dataIndex: "status",
            hasSort: false,
            render: (col, row) => (
              <>
                <p className={`status status-${get(row, "status")}`}>{get(row, "status_label")}</p>

                {get(row, "shipping_time") ? (
                  <Badge color="red" radius="sm">
                    {get(row, "shipping_time")}
                  </Badge>
                ) : null}
                {get(row, "sending_date") ? (
                  <div className="sending-date" radius="sm">
                    {time.to(get(row, "sending_date"), "DD.MMM")}
                  </div>
                ) : null}
                <div className="badges">
                  <Tooltip title="Admin puli to'lab berilgan">
                    {get(row, "bonus_paid_at") ? <Icons.Paid className="package" /> : null}
                  </Tooltip>

                  {get(row, "status") === "accepted" && get(row, "packed_at") ? (
                    <Tooltip title="Buyurtma qadoqlangan va kuryer kelishi kutilmoqda">
                      <Icons.Package className="package" />
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ),
          },
          {
            title: "Izoh",
            dataIndex: "note",
            hasSort: false,
            render: (cell, row) => {
              const hasReason = get(row, "status") === "cancelled" && get(row, "cancel_reason")
              return (
                <>
                  <p>{cell}</p>
                  {hasReason ? (
                    <p className="c-blue">#Kuryer izohi: {get(row, "cancel_reason")}</p>
                  ) : null}
                </>
              )
            },
          },
          {
            title: "Sana",
            hasSort: false,
            dataIndex: "updated_at_label",
            render: (cell) => <p className="date-label">{cell}</p>,
          },
        ]}
      />
      <Paginate meta={get(orders, "data.meta")} pageCount={get(orders, "data.meta.last_page")} />

      <Modal
        title="Buyrtmani biriktirish"
        isOpen={!!orderAttaching}
        handleClose={() => setOrderAttaching(null)}
        className="modal-attach-order"
      >
        <ModalContents.Confirm
          onConfirm={() => acceptOrder(get(orderAttaching, "id"))}
          onReject={() => setOrderAttaching(null)}
          text={
            <p className="alert">
              &#x26A0; Buyurtmani qabul qilganingizdan keyin sayt operatori ushbu buyurtma bilan
              umumun bog'lanmaydi.
            </p>
          }
          isLoading={accept.isLoading}
        />
      </Modal>
    </div>
  )
}

export default Request
