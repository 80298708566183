/* eslint-disable react/no-array-index-key */
import { Fields, Icons, Modal } from "components"

import "./styles.scss"
import { Form } from "containers"
import { useState } from "react"
import { get } from "lodash"
import { notification } from "services"
import { useRequest } from "hooks"
import Fancyapps from "components/Fancyapps"

function VideoPost({ activeProduct, setActiveProduct }) {
  const [isCreating, setIsCreating] = useState(false)

  const query = useRequest({
    url: `products/${get(activeProduct, "id")}`,
    enabled: !!get(activeProduct, "id"),
  })

  const posts = get(query.data, "data.posts") || []

  return (
    <Modal
      isOpen={!!activeProduct}
      handleClose={() => {
        setActiveProduct()
      }}
      hasOutsideClick={false}
      className="modal-news modal-product-post"
    >
      {!isCreating && (
        <div className="box">
          <div className="f-c-sb">
            <h2>Adminlar uchun kreativlar</h2>
            <Icons.XIcon
              className="modal-x-icon"
              onClick={() => {
                setActiveProduct()
                setIsCreating(false)
              }}
            />
          </div>
          <h4 className="mt-30">Qo‘shimcha videolar</h4>

          <div className="posts">
            {query.isLoading
              ? Array.from({ length: 4 }, (item, i) => (
                  <div className="product-post-card" key={i}>
                    <div className="left w-full">
                      <p className="sk icon"> </p>
                      <div className="content w-full">
                        <p className="title sk mb-1 w-30"> </p>
                        <p className="size sk w-70">{get(item, "description")}</p>
                      </div>
                    </div>
                    <div className="right">
                      <p className="sk icon"> </p>
                      <p className="sk icon"> </p>
                    </div>
                  </div>
                ))
              : null}
            {!query.isLoading && posts.length === 0 && (
              <div className="center">
                <Icons.NoData />
              </div>
            )}
            {posts.map((item, i) => (
              <div
                className={`product-post-card ${get(item, "is_active") ? "active" : ""}`}
                key={i}
              >
                <div className="left">
                  <Icons.Type className="icon-type" />
                  <div className="content">
                    <p className="title">{get(item, "title")}</p>
                    <p className="size">{get(item, "description")}</p>
                  </div>
                </div>
                <div className="right">
                  <Icons.Download
                    onClick={() => {
                      const a = document.createElement("a")
                      a.href = get(item, "video")
                      a.setAttribute("download", true)
                      a.setAttribute("target", "_blank")
                      a.click()
                    }}
                  />
                  <Fancyapps>
                    <a href={get(item, "video")} data-fancybox="gallery">
                      <Icons.View />
                    </a>
                  </Fancyapps>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {isCreating && (
        <Form
          url={`products/${get(activeProduct, "id")}/add-post`}
          fields={[
            {
              name: "video",
              type: "array",
              submitValue: (files) => files[0],
            },
          ]}
          onSuccess={(res, { reset }) => {
            notification.success()
            reset()
            setIsCreating(false)
            query.refetch()
          }}
          isFormdata
        >
          {({ isLoading }) => (
            <>
              <Fields.Input
                name="title"
                label="Kreativ nomini yozing"
                placeholder="Mahsulot kreativi"
                required
              />
              <Fields.Textarea
                name="description"
                label="Ta’rif yozing"
                placeholder="Qo‘shimicha ma’lumotlar haqida yozing"
                required
                rows={3}
              />
              <Fields.TicketUpload
                name="video"
                label="Video"
                isMulti={false}
                placeholder="Yuklanayotgan videolar 1:1 yoki 3:4 formatida yuklang. Yuklanayotgan videoda mahsulot narxi va yetkazib berish bo'yicha ma'lumotlar bo'lishi mumkin emas. Videoda telefon raqam yo'q ekanligiga ishonch hosil qiling!"
              />
              {isCreating && (
                <div className="btn-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => setIsCreating(true)}
                    disabled={isLoading}
                  >
                    Kreativ qo‘shish
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => setIsCreating(false)}
                  >
                    ortga
                  </button>
                </div>
              )}
            </>
          )}
        </Form>
      )}
      {!isCreating && (
        <div className="btn-group">
          <button type="button" className="btn btn-primary" onClick={() => setIsCreating(true)}>
            Kreativ qo‘shish
          </button>
          <p className="c-green f-m">Har bir maqullangan kreativ uchun 50 coin beriladi.</p>
        </div>
      )}
      <div
        className="close-icon"
        onClick={() => {
          setActiveProduct()
          setIsCreating(false)
        }}
      >
        <Icons.XIcon />
      </div>
    </Modal>
  )
}

export default VideoPost
