import { createRoot } from "react-dom/client"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { QueryClientProvider } from "@tanstack/react-query"
import { Toaster } from "react-hot-toast"

import Routes from "Routes/index"
import { queryClient } from "config"

import "react-tippy/dist/tippy.css"
import "assets/styles/index.scss"

const root = createRoot(document.getElementById("root"))

root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <Routes />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    <Toaster position="top-right" />
  </>,
)
