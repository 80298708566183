import { CustomTable, Icons } from "components"
import { Form } from "containers"
import { useState } from "react"
import useGlobalState from "hooks/useGlobalState"
import { Button, Loader } from "@mantine/core"
import Paginate from "Advertiser/components/Paginate"
import { get } from "lodash"
import { helpers, notification, time } from "services"
import { useMutate, useRequest } from "hooks"
import constants from "store/constants"
import Input from "components/Fields/Input"
import Fancyapps from "components/Fancyapps"

import "./styles.scss"
import useGetme from "hooks/useGetme"

function Payment() {
  const [state, setState] = useGlobalState()
  const [page, setPage] = useState(0)
  const [cancelID, setCancelID] = useState()
  const { balance } = useGetme()

  const updateBalance = (data) => {
    setState({
      user: {
        ...(state.user || {}),
        balance: get(data, "data.remain_balance"),
      },
    })
  }

  const withdraws = useRequest({
    url: `users/${get(state, "user.id")}/withdraws`,
    route: "",
    urlSearchParams: {
      page,
    },
  })

  const cancel = useMutate({
    url: "withdraws/",
    route: "",
    onSuccess: (data) => {
      updateBalance(data)
      notification.success()
      withdraws.refetch()
      setCancelID()
      window.localStorage.removeItem("profile")
    },
  })

  return (
    <div className="payment">
      <div className="box p-20 mt-3">
        <div className="row box g-20 mb-3">
          <div className="card-balance col f-c-sb">
            <div className="content">
              <p className="key">
                <span className="txt-light">ID raqam:</span> #0018211
              </p>
            </div>
          </div>
        </div>
        <div className="row box g-20">
          <div className="card-balance col f-c-sb">
            <div className="content">
              <p className="key">Asosiy balans</p>
              <p className="value c-primary">{helpers.toReadable(balance)} so‘m</p>
            </div>
          </div>
          <div className="card-balance col f-c-sb">
            <div className="content">
              <p className="key">Yechib olingan</p>
              <p className="value c-light">
                {helpers.toReadable(get(withdraws.data, "stats.total_paid"))} so‘m
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="form-wrapper mt-5">
        <p className="section-title">Hisobdan pul yechish</p>
        <Form
          url="withdraws"
          key={get(withdraws.data, "data[0].id")}
          route=""
          className="box p-3"
          initialValues={{
            card_number: get(withdraws.data, "data[0].card_number"),
            amount: get(withdraws.data, "data[0].amount"),
          }}
          fields={[
            {
              name: "card_number",
            },
            {
              name: "amount",
              type: "number",
              max: 50000000,
              submitvalue: (val) => (val ? +val : null),
            },
          ]}
          onSuccess={(data) => {
            withdraws.refetch()
            updateBalance(data)
            notification.success()
          }}
          onSubmit={() => window.localStorage.removeItem("profile")}
        >
          {({ isLoading }) => (
            <div className="row">
              <div className="col">
                <Input name="card_number" placeholder="Karta raqamingizni kiriting" />
              </div>
              <div className="col">
                <Input type="number" name="amount" placeholder="Summani kiriting" />
              </div>
              <button type="submit" className="btn col-lg-3" disabled={isLoading}>
                <p>Tasdiqlash</p>
                {isLoading && <Loader color="yellow" size={20} />}
              </button>
            </div>
          )}
        </Form>
      </div>
      <div className="list mt-5">
        <p className="section-title">Hisobdan pul yechish</p>
        <CustomTable
          data={get(withdraws.data, "data") || []}
          isLoading={withdraws.isLoading}
          className="adv-table"
          rowClassName={(row) => `status-${get(row, "status")}`}
          columns={[
            {
              title: "Sana",
              dataIndex: "created_at",
              width: 200,
              hasSort: false,
              render: (date) => (
                <p style={{ whiteSpace: "nowrap" }}>{date && time.to(date, "YYYY-MM-DD")}</p>
              ),
            },
            {
              title: "Hisob raqam",
              dataIndex: "card_number",
              hasSort: false,
            },
            {
              title: "Summa",
              dataIndex: "amount",
              hasSort: false,
            },
            // {
            //   title: "Holat",
            //   dataIndex: "status",
            //   hasSort: false,
            //   render: (status) => ,
            // },
            {
              title: "Holati",
              dataIndex: "status",
              className: "t-center",
              hasSort: false,
              render: (cell, row) => (
                <>
                  {get(row, "status") === "new" ? (
                    <Button
                      loaderPosition="center"
                      color="red"
                      className="payment-cancel"
                      loading={cancelID === get(row, "id") ? cancel.isLoading : false}
                      onClick={() => {
                        const id = get(row, "id")
                        setCancelID(id)
                        cancel.mutate({
                          extraUrl: `${id}/cancel`,
                        })
                      }}
                    >
                      {cancelID === get(row, "id") ? cancel.isLoading : <Icons.XIcon />}
                    </Button>
                  ) : (
                    get(constants.transactionStatuses, cell, cell)
                  )}
                  {get(row, "screen") ? (
                    <Fancyapps>
                      <a href={get(row, "screen")} data-fancybox>
                        <Icons.ImageIcon className="screen" />
                      </a>
                    </Fancyapps>
                  ) : null}
                </>
              ),
            },
          ]}
        />
      </div>
      <Paginate
        pageRangeDisplayed={4}
        pageCount={get(withdraws.data, "meta.last_page")}
        setPage={(e) => setPage(e.selected)}
        meta={get(withdraws.data, "meta")}
      />
    </div>
  )
}

export default Payment
