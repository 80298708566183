import { Link } from "react-router-dom"
import { helpers } from "services"
import { get } from "lodash"

import "./styles.scss"

function ProductList({ hasPagination = true, data = {}, setPage = () => {}, isLoading }) {
  const { data: products = [], meta = {} } = data

  return (
    <div className="front-product-list">
      <div className="container">
        <div className="products">
          {isLoading &&
            Array(10)
              .fill(1)
              .map((_, i) => (
                <article className="product__card" key={i}>
                  <div>
                    <div className="img sk" style={{ height: "240px" }}>
                      {" "}
                    </div>
                  </div>
                  <div className="content">
                    <h3 className="title">
                      <span className="sk" style={{ height: "20px" }}>
                        {" "}
                      </span>
                      <span className="sk mt-1" style={{ height: "20px", width: "70%" }}>
                        {" "}
                      </span>
                    </h3>
                    <p className="price sk" style={{ width: "40%" }}>
                      {" "}
                    </p>
                  </div>
                </article>
              ))}
          {products.map(({ image = "", title = "", price = "", slug = "" }, i) => (
            <article className="product__card" key={i}>
              <Link to={`/product/${slug}`} className="thumbnail">
                <img src={image} alt="" />
              </Link>
              <div className="content">
                <h3 className="title">
                  <Link to={`/product/${slug}`}>{title}</Link>
                </h3>
                <p className="price">{helpers.toReadable(price)} so'm</p>
              </div>
            </article>
          ))}
        </div>
        {hasPagination && get(meta, "current_page") < get(meta, "last_page") && (
          <div className="pagination">
            <button
              type="button"
              className="btn pagination-btn"
              onClick={() => {
                setPage(get(meta, "current_page") + 1)
              }}
              disabled={isLoading}
            >
              Yana ko‘rsatish
            </button>
          </div>
        )}
        {isLoading && (
          <div className="center mt-30">
            <div className="loader"> </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductList
