/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper/modules"

import "swiper/css"
import "swiper/css/pagination"
import "./styles.scss"
import useStore from "hooks/useStore"

function Banners() {
  const { banner_path } = useStore()

  return (
    <Swiper
      className="home-banner"
      modules={[Pagination]}
      spaceBetween={10}
      slidesPerView={1}
      pagination={{ clickable: true }}
    >
      <SwiperSlide>
        <div className="container">
          <img
            src={
              banner_path ||
              "https://static.100k.uz/banners/desktop/k7keBchmXuLHknQ1wCi8BTrlIY95hLPo9SZR2pdm.png"
            }
            alt=""
          />
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default Banners
