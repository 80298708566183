import { Fields } from "components"
import { Form } from "containers"
import { debounce } from "lodash"
import { useSearchParams } from "react-router-dom"

const searchDebounce = debounce((cb) => cb(), 700)

function Search() {
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get("search")

  return (
    <Form
      key={query}
      initialValues={{
        search: query,
      }}
    >
      {() => (
        <Fields.Search
          name="search"
          onChange={(value) => {
            searchDebounce(() => {
              searchParams.set("search", value)
              searchParams.set("page", 1)
              setSearchParams(searchParams)
            })
          }}
        />
      )}
    </Form>
  )
}

export default Search
