import { Icons } from "components"
import { Form } from "containers"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import useGlobalState from "hooks/useGlobalState"

import "./styles.scss"
import { get } from "lodash"
import useStore from "hooks/useStore"

const searchDisabled = ["oqim", "product"]

function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()
  const store = useStore()
  const [searchParams] = useSearchParams()
  const [state] = useGlobalState()

  const name = get(state, "user.name")

  const search = (
    <Form
      className="desktop-search"
      onSubmit={(values) => {
        searchParams.set("search", values.search)
        searchParams.delete("page")
        searchParams.delete("category_title")
        searchParams.delete("category_id")
        navigate(`/products?${searchParams.toString()}`)
      }}
    >
      {({ register }) => (
        <label htmlFor="search-input">
          <Icons.Search className="icon-search" />
          <input
            id="search-input"
            type="text"
            placeholder="Maxsulot qidirish..."
            {...register("search")}
          />
        </label>
      )}
    </Form>
  )

  return (
    <nav className="front-nav">
      <div className="container">
        <div className="f-c-sb g-5">
          <Link to="/">
            <img src={get(store, "logo_path")} alt="" className="logo" />
          </Link>
          <div className="desktop search-wrapper">{search}</div>
          <Link to="/admin" className="link-admin">
            <Icons.FrontUser />
            <span> {name || "Kirish"}</span>
          </Link>
        </div>
      </div>
      <div
        className="mobile mobile-search"
        style={{
          display: searchDisabled.includes(`${location.pathname.split("/")[1]}`) ? "none" : "block",
        }}
      >
        {search}
      </div>
    </nav>
  )
}

export default Navbar
