import ReactPaginate from "react-paginate"
import { useSearchParams } from "react-router-dom"

import "./paginate.scss"
import { Icons } from "components"
import { get } from "lodash"

function Paginate({
  setPage = () => {},
  pageCount = null,
  meta,
  withUrl = true,
  name = "page",
  ...props
}) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div className="adv-pagination">
      <ReactPaginate
        key={JSON.stringify(meta || {})}
        breakLabel="..."
        nextLabel={
          <span className="adv-pagination__next">
            <span>Oldinga</span>
            <Icons.ArrowLeft />
          </span>
        }
        previousLabel={
          <span className="adv-pagination__prev">
            <Icons.ArrowLeft />
            <span>Ortga</span>
          </span>
        }
        onPageChange={(page) => {
          if (withUrl) {
            searchParams.set(name, page.selected + 1)
            setSearchParams(searchParams)
          }
          setPage({ selected: page.selected + 1 })
        }}
        pageRangeDisplayed={5}
        pageCount={pageCount || get(meta, "last_page")}
        renderOnZeroPageCount={null}
        forcePage={(get(meta, "current_page") || searchParams.get("page") || 1) - 1}
        {...props}
      />
    </div>
  )
}

export default Paginate
