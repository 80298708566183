import { lazy } from "react"
import { helpers } from "services"

import Fields from "./Fields"
import { Icons } from "./Icons"
import ModalContents from "./ModalContents"
import Skeleton from "./Skeleton"

const Map = helpers.withSuspense(lazy(() => import("./Map")))
const Tabs = helpers.withSuspense(lazy(() => import("./Tabs")))
const Modal = helpers.withSuspense(lazy(() => import("./Modal")))
const Delete = helpers.withSuspense(lazy(() => import("./Delete")))
const Filter = helpers.withSuspense(lazy(() => import("./Filter")))
const Spinner = helpers.withSuspense(lazy(() => import("./Spinner")))
const Sidebar = helpers.withSuspense(lazy(() => import("./Sidebar")))
const ListSort = helpers.withSuspense(lazy(() => import("./ListSort")))
const Paginate = helpers.withSuspense(lazy(() => import("./Paginate")))
const BoolenCol = helpers.withSuspense(lazy(() => import("./BoolenCol")))
const PageHeader = helpers.withSuspense(lazy(() => import("./PageHeader")))
const RechartPie = helpers.withSuspense(lazy(() => import("./RechartPie")))
const CustomTable = helpers.withSuspense(lazy(() => import("./CustomTable")))
const ProductCard = helpers.withSuspense(lazy(() => import("./ProductCard")))
const Transactions = helpers.withSuspense(lazy(() => import("./Transactions")))
const DropdownMenu = helpers.withSuspense(lazy(() => import("./DropdownMenu")))
const GoBackHeader = helpers.withSuspense(lazy(() => import("./GoBackHeader")))
const ErrorBoundary = helpers.withSuspense(lazy(() => import("./ErrorBoundary")))
const PageNavigation = helpers.withSuspense(lazy(() => import("./PageNavigation")))
const SelectModalList = helpers.withSuspense(lazy(() => import("./SelectModalList")))
const ColumnTitleSort = helpers.withSuspense(lazy(() => import("./ColumnTitleSort")))
const SelectSubCategories = helpers.withSuspense(lazy(() => import("./SelectSubCategories")))

export {
  Map,
  Fields,
  Icons,
  Tabs,
  Modal,
  Delete,
  Filter,
  Spinner,
  Sidebar,
  ListSort,
  Skeleton,
  Paginate,
  BoolenCol,
  PageHeader,
  RechartPie,
  CustomTable,
  ProductCard,
  GoBackHeader,
  DropdownMenu,
  Transactions,
  ErrorBoundary,
  ModalContents,
  PageNavigation,
  SelectModalList,
  ColumnTitleSort,
  SelectSubCategories,
}
