import { useQueryClient } from "@tanstack/react-query"
import { get } from "lodash"

function useGetme() {
  const queryClient = useQueryClient()

  const getme = get(queryClient.getQueryData(["getme"]), "data.data") || {}

  return getme
}

export default useGetme
