import { Link } from "react-router-dom"
import "../../ProductCard/styles.scss"

function ProductCard() {
  return (
    <div className="product-card">
      <Link to="." alt="" className="card__img">
        <img className="sk" alt="" style={{ height: "200px" }} />
      </Link>
      <ul>
        <li>
          <h2 className="c-red sk"> </h2>
          <p className="price sk" style={{ marginTop: "10px" }}>
            {" "}
          </p>
        </li>
        <li>
          <p className="sk"> </p>
        </li>
        <li>
          <p className="sk"> </p>
        </li>
        <li>
          <p className="sk"> </p>
        </li>
        <li>
          <a aria-current="page" className="c-red active sk" href="/market">
            {" "}
          </a>
        </li>
        <li>
          <div className="sk" style={{ height: "30px", margin: "5px 10px", width: "90%" }}>
            {" "}
          </div>
        </li>
      </ul>
    </div>
  )
}

export default ProductCard
