import { Icons } from "components"

import "./styles.scss"
import { get } from "lodash"
import useStore from "hooks/useStore"

function Footer() {
  const store = useStore()

  return (
    <footer className="front-footer">
      <div className="items-wrapper">
        <div className="container">
          <div className="items">
            <div className="item">
              <div className="icon">
                <Icons.Truck />
              </div>
              <p className="title ">Tezkor yetkazib berish !</p>
              <p className="desc">
                Buyurtmangiz O'zbekistonning barcha viloyatlariga 2 kun ichida yetqazib beriladi
              </p>
            </div>
            <div className="item">
              <div className="icon">
                <Icons.Truck />
              </div>
              <p className="title ">Tezkor qo‘llab quvvatlash !</p>
              <p className="desc">Dam olish kunlarisiz qo'llab quvvatlash bo'limi mavjud.</p>
            </div>
            <div className="item">
              <div className="icon">
                <Icons.Truck />
              </div>
              <p className="title ">To‘lov istalgan usulda !</p>
              <p className="desc">
                Buyurtmani oldindan click, payme yoki naqt ko‘rinishida amalga oshiring.
              </p>
            </div>
            <div className="item">
              <div className="icon">
                <Icons.Truck />
              </div>
              <p className="title ">Mijozlarga turli sovg’alar !</p>
              <p className="desc">Doimiy mijozlar uchun sovg'alar va bonuslar taqdim etiladi.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-secondary">
        <div className="container">
          <div className="main">
            <img src={get(store, "logo_path")} alt="" className="logo" />
            <div className="f-c-sb g-10 w-100 footer-center">
              <ul>
                <li>
                  <a href="/">Xaridorlar uchun</a>
                </li>
                <li>
                  <a href="/">Foydalanuvchi shartnomasi</a>
                </li>
                <li>
                  <a href="/">Ommaviy oferta</a>
                </li>
                <li>
                  <a href="/">Biz haqimizda</a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="/">Ijtimoiy tarmoqlar</a>
                </li>
                <li>
                  <a href="/">
                    <Icons.TelegramFilled />
                    <span>Telegram</span>
                  </a>
                </li>
                <li>
                  <a href="/">
                    <Icons.InstagramFilled />
                    <span>Instagram</span>
                  </a>
                </li>
                <li>
                  <a href="/">
                    <Icons.Youtube />
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="/">Biz bilan bog’laning</a>
                </li>
                <li>
                  <a href="/">
                    <Icons.PhoneFilled />
                    <span>+998 90 000 00 00</span>
                  </a>
                </li>
                <li>
                  <a href="/">
                    <Icons.Email />
                    <span>mail@gmail.com</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="container f-c-sb">
          <p className="txt-left">Barcha huquqlar himoyalangan</p>
          <p className="txt-right">© 2024. "{window.location.hostname}" CPA tizimi. </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
