import { ErrorBoundary } from "components"
import Layout from "Admin/Layout"
import { Navigate } from "react-router-dom"

import Pages from "./Pages"

const AdminRoutes = [
  {
    path: "dashboard",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Pages.Statistics />,
      },
      {
        path: "product",
        element: <Pages.ProductList />,
      },
      {
        path: "account-history",
        element: <Pages.AccountHistoryList />,
      },
      {
        path: "product/create",
        element: <Pages.ProductCreate />,
      },
      {
        path: "product/update/:id",
        element: <Pages.ProductUpdate />,
      },

      {
        path: "income",
        element: <Pages.IncomeList />,
      },

      {
        path: "outgoing",
        element: <Pages.OutgoingList />,
      },

      {
        path: "withdraw",
        element: <Pages.WithdrawList />,
      },

      {
        path: "stream",
        element: <Pages.StreamList />,
      },
      {
        path: "stream/update/:id",
        element: <Pages.StreamUpdate />,
      },

      {
        path: "cancelled-income",
        element: <Pages.CancelledIncomeList />,
      },

      {
        path: "profile",
        element: <Pages.Profile />,
      },
      {
        path: "profile/penalty/:id",
        element: <Pages.ProfilePenaltyReply />,
      },

      {
        path: "order/:status",
        element: <Pages.OrderList />,
      },
      {
        path: "order/create",
        element: <Pages.OrderCreate />,
      },
      {
        path: "order/update/:id",
        element: <Pages.OrderUpdate />,
      },
      {
        path: "order/print",
        element: <Pages.PrintOrders />,
      },

      {
        path: "account-transactions/:id",
        element: <Pages.AccountTransactions />,
      },

      {
        path: "user",
        children: [
          {
            index: true,
            element: <Pages.OperatorList />,
          },
          {
            path: "create",
            element: <Pages.OperatorCreate />,
          },
          {
            path: "admin",
            element: <Pages.AdminList />,
          },
        ],
      },
      {
        path: "finance",
        children: [
          {
            index: true,
            element: <Pages.AccountingList />,
          },
          {
            path: "accounting/create",
            element: <Pages.AccountingCreate />,
          },

          {
            path: "sale",
            element: <Pages.SaleList />,
          },
        ],
      },
      {
        index: true,
        path: "*",
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
]

export default AdminRoutes
