/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import { Fields, Icons, Modal, Paginate, Skeleton } from "components"
import { useMutate, useRequest } from "hooks"
import { get } from "lodash"
import { useState } from "react"
import { helpers, time } from "services"

import "./styles.scss"
import GoBackHeader from "components/GoBackHeader"
import useGlobalState from "hooks/useGlobalState"
import { Form } from "containers"
import { Alert, Button } from "@mantine/core"

function Stream() {
  const [importing, setImporting] = useState()
  const [uploaded, setUploaded] = useState(false)
  const [page, setPage] = useState()
  const [deletingItem, setDeletingItem] = useState()
  const [state] = useGlobalState()
  const streamsQuery = useRequest({
    url: `users/${get(state, "user.id")}/streams`,
    route: "",
    urlSearchParams: { page, conversion: 1 },
  })
  const streamList = get(streamsQuery.data, "data") || []

  const deleteQuery = useMutate({
    url: "advertiser/streams",
    method: "delete",
    onSuccess: () => {
      setDeletingItem()
      streamsQuery.refetch()
    },
  })

  return (
    <div className="stream">
      <GoBackHeader title="Mening havolalarim" />
      <div className="stream__cards">
        {streamsQuery.isLoading
          ? Array(5)
              .fill(1)
              .map((_, i) => <Skeleton.Stream key={i} />)
          : streamList.map((stream) => {
              const { id, name, created_at } = stream
              const link = `${window.location.origin}/oqim/${id}`

              return (
                <div className="stream__card p-15">
                  <div className="title">{name}</div>
                  <div className="link">{link}</div>
                  <div className="item">
                    <p className="key">Yaratilgan sana:</p>
                    <p className="value">{time.to(created_at, "DD.MM.YYYY")}</p>
                  </div>
                  <div className="item">
                    <p className="key">Yaratilgan vaqti:</p>
                    <p className="value">{time.to(created_at, "HH:mm:ss")}</p>
                  </div>
                  <div className="f-c g-10 mt-20">
                    <div className="icon-delete" onClick={() => setDeletingItem(stream)}>
                      <Icons.Delete />
                    </div>
                    <button type="button" className="btn-copy" onClick={() => helpers.copy(link)}>
                      Nusxa ko‘chirish
                    </button>
                    <button type="button" className="btn-import" onClick={() => setImporting(id)}>
                      <i className="fa-solid fa-file-import" />
                    </button>
                  </div>
                </div>
              )
            })}
      </div>
      <Paginate
        meta={get(streamsQuery, "data.meta")}
        pageCount={get(streamsQuery, "data.meta.last_page")}
        setPage={(e) => setPage(e.selected)}
      />

      <Modal
        isOpen={!!importing}
        handleClose={() => setImporting()}
        title="Targetdan tushgan leadlarni import qilish"
        className="modal-import"
      >
        <Form
          key={importing}
          url={`streams/${importing}/import-leads`}
          route=""
          className="p-20"
          fields={[
            {
              name: "file",
              type: "array",
              submitValue: (value) => value && value[0],
            },
          ]}
          onSuccess={() => {
            setImporting()
            setUploaded(true)
          }}
          isFormdata
        >
          {({ isLoading }) => (
            <>
              <Fields.TicketUpload
                name="file"
                label="Facebookdan olingan .csv faylni yuklang"
                placeholder="csv faylni tanlang"
                accept=".xlsx, .xls, .csv"
              />
              <div className="f-c-e g-10 mt-20">
                <Button type="submit" loading={isLoading}>
                  Jo'natish
                </Button>
                <Button
                  type="submit"
                  color="gray"
                  variant="white"
                  onClick={() => setImporting()}
                  disabled={isLoading}
                >
                  Bekor qilish
                </Button>
              </div>
            </>
          )}
        </Form>
      </Modal>
      <Modal
        isOpen={uploaded}
        handleClose={() => setUploaded(false)}
        className="modal-import-success"
      >
        <div className="p-20">
          <Alert
            color="green"
            title="Leadlar muvaffaqiyatli yuklandi"
            icon={
              <i
                className="fa-solid fa-circle-check"
                style={{ fontSize: "30px", display: "block" }}
              >
                {" "}
              </i>
            }
          />
          <div className="center">
            <Button color="green" onClick={() => setUploaded(false)} className="mt-10">
              Yopish
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={!!deletingItem}
        className="modal-delete-stream"
        maxWidth={600}
        handleClose={() => setDeletingItem()}
      >
        <div className="top">
          <h1>Oqim o‘chirilmoqda</h1>
          <p className="desc">
            Siz rostdan ham Oqimni o‘chirmoqchimisiz ? Agar o‘chirsangiz uni qayta tiklab bo‘lmaydi
          </p>
        </div>
        <div className="bottom">
          <p className="name">{get(deletingItem, "name")}</p>
          <p className="link">{get(deletingItem, "link")}</p>
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-delete"
              onClick={() =>
                deleteQuery.mutate({
                  id: get(deletingItem, "id"),
                })
              }
            >
              O‘chirish
            </button>
            <button type="button" className="btn btn-cancel" onClick={() => setDeletingItem()}>
              Bekor qilish
            </button>
          </div>
        </div>
        <div className="adv-modal-close" onClick={() => setDeletingItem()}>
          <Icons.XIcon />
        </div>
      </Modal>
    </div>
  )
}

export default Stream
