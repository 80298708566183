import { get } from "lodash"
import storage from "services/Storage"
import notification from "services/notification"

function errorHandler(err) {
  const { pathname } = window.location

  if (get(err, "response.status") === 401) {
    storage.remove("user")
    storage.remove("advUser")
    storage.remove("token")

    if (pathname.includes("admin")) window.location.pathname = "admin/signin"

    if (pathname.includes("dashboard")) window.location.pathname = "dashboard/signin"
  }

  if (get(err, "response.status") === 403 && pathname.includes("dashboard")) {
    window.location.pathname = "dashboard/restricted"
  }

  if (get(err, "response.status") >= 400 && get(err, "response.status") < 500) {
    const message = get(err, "response.data.message") || get(err, "response.data.data.message")

    if (message) notification.error(message)
    else notification.error()
  }
  if (get(err, "response.status") >= 500) {
    notification.error("Serverda xatolik")
  }

  return null
}

export default errorHandler
