import { Icons, Modal, ModalContents } from "components"
import { useEffect, useRef, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import MobileMenu from "Admin/Layout/MobileMenu"
import useGlobalState from "hooks/useGlobalState"
import { get } from "lodash"
import { useMutate } from "hooks"
import { notification } from "services"
import useGetme from "hooks/useGetme"
import navbar from "services/helpers/navbar"

import avatar from "assets/images/png/avatar.png"

import "./navbar.scss"

function Navbar() {
  const ref = useRef(null)
  const navigate = useNavigate()
  const getme = useGetme()

  const [active, setActive] = useState(false)
  const [isOpen, setOpen] = useState()
  const [state, setState] = useGlobalState()

  const outsideClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setActive(false)
    }
  }

  useEffect(() => {
    window.addEventListener("click", outsideClick)
    return () => window.removeEventListener("click", outsideClick)
  }, [])

  // const openMenu = () => {
  //   const menu = document.querySelector(".mobile-menu")
  //   if (menu) {
  //     menu.classList.add("menu-open")
  //   }
  // }

  const { mutate, isLoading } = useMutate({
    url: "passport/logout",
    onSuccess: () => {
      const isAdv = window.location.pathname.includes("admin")
      localStorage.removeItem("token")
      setState({ isAuth: false })
      navigate(isAdv ? "/admin/signin" : "/dashboard/signin")
      notification.success()
    },
  })

  const signOut = () => {
    if (!isLoading) {
      mutate({
        data: {
          fcm_token: localStorage.getItem("token"),
        },
        route: "v1",
      })
    }
  }

  return (
    <>
      <nav className="nav">
        <div className="nav__left">
          {navbar.map(
            (nav) =>
              nav.roles.includes(get(getme, "role")) && (
                <div className="nav__items" key={nav.to}>
                  <NavLink
                    to={nav.to}
                    className={() => {
                      const isActive = window.location.pathname === nav.to
                      if (isActive) return "active nav__item"
                      return "nav__item"
                    }}
                  >
                    {nav.icon}
                    <p className="nav__item-text">{nav.label}</p>
                  </NavLink>
                </div>
              ),
          )}
        </div>
        <div className="nav__right">
          <div className="avatar" ref={ref} onClick={() => setActive(!active)}>
            <img src={get(state, "user.avatar_url") || avatar} alt="" />
            <ul
              className={`avatar__dropdown ${active ? "active" : ""}`}
              onClick={(e) => e.stopPropagation()}
            >
              <NavLink to="/dashboard/profile" onClick={() => setActive(false)}>
                Profil
              </NavLink>
              <NavLink to="/admin" onClick={() => setActive(false)}>
                Admin
              </NavLink>
              <NavLink to="/" onClick={() => setActive(false)}>
                Websayt
              </NavLink>
              <li onClick={() => setOpen(true)}>
                Tizimdan chiqish
                <Icons.Exit width="15px" />
              </li>
            </ul>
          </div>
          {/* <div className="nav__burger" onClick={openMenu}>
            <span> </span>
            <span> </span>
            <span> </span>
          </div> */}
        </div>
        <Modal isOpen={isOpen} handleClose={() => setOpen(false)} title="Tizimdan chiqish">
          <ModalContents.Confirm
            onConfirm={signOut}
            onReject={() => setOpen(false)}
            isLoading={isLoading}
            text="Tizimdan chiqishni xohlaysizmi?"
          />
        </Modal>
      </nav>
      <MobileMenu />
    </>
  )
}

export default Navbar
