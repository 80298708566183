function Stream() {
  return (
    <div className="stream__card p-15">
      <div className="title sk"> </div>
      <div className="link sk" style={{ height: "30px" }}>
        {" "}
      </div>
      <div className="item">
        <p className="key sk"> </p>
        <p className="value sk"> </p>
      </div>
      <div className="item">
        <p className="key sk"> </p>
        <p className="value sk"> </p>
      </div>
      <div className="f-c g-10 mt-20">
        <div className="icon-delete"> </div>
        <button type="button" className="btn-copy sk">
          {" "}
        </button>
      </div>
    </div>
  )
}

export default Stream
