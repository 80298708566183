import React from "react"

import "./styles.scss"
import { Link } from "react-router-dom"

function OrderSuccess() {
  return (
    <div className="page-order-success">
      <div className="container">
        <h2 className="title">Rahmat! Sizning buyurtmangiz muvaffaqiyatli qabul qilindi!</h2>
        <p className="desc">
          Tez orada menejerlarimiz ko'rsatilgan ma'lumotlari yordamida siz bilan bog'lanishadi!
        </p>

        <Link to="/" className="btn">
          Asosiy sahifaga qaytish
        </Link>
      </div>
    </div>
  )
}

export default OrderSuccess
