/* eslint-disable import/no-unresolved */
import { Button } from "@mantine/core"
import { Fields, GoBackHeader, Icons, Modal, ProductCard, Skeleton } from "components"
import { Form } from "containers"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import useGlobalState from "hooks/useGlobalState"
import Categories from "Advertiser/components/Categories"
import Search from "Advertiser/components/fields/Search"
import Paginate from "Advertiser/components/Paginate"

import { get } from "lodash"
import { useRequest } from "hooks"
import { helpers, notification } from "services"
import VideoPost from "./VideoPost"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import "./styles.scss"

const statuses = [
  {
    text: "Yangi 🕒",
    value: "new",
  },
  {
    text: "TOP ⭐",
    value: "top",
  },
  {
    text: (
      <span className="f-c">
        Zaxira{" "}
        <Icons.ArrowLeft
          className="arrow-up"
          style={{ display: "block", width: "18px", height: "18px", rotate: "90deg" }}
        />
      </span>
    ),
    value: "quantity",
  },
]

function Market() {
  const [page, setPage] = useState(0)
  const [state] = useGlobalState()
  const [searchParams] = useSearchParams()
  const [selectedProduct, setSelectedProduct] = useState()
  const [link, setLink] = useState()
  const productId = searchParams.get("create_stream")
  const [activePostsProduct, setActivePostsProduct] = useState()

  const productParams = {
    page,
    status: "active",
  }

  const active = searchParams.get("status") || "new"

  if (typeof active === "number") productParams.category_id = active
  else if (active === "my-products") productParams.advertiser_id = get(state, "user.id")
  else if (typeof active === "string") productParams.sort = active

  if (searchParams.get("query")) productParams.search = searchParams.get("query")

  const productOne = useRequest({
    url: `products/${productId}`,
    enabled: !!productId,
  })

  const products = useRequest({
    url: "products",
    route: `stores/${process.env.REACT_APP_STORE_ID}`,
    urlSearchParams: productParams,
  })
  const productsList = get(products.data, "data") || []

  useEffect(() => {
    if (get(productOne.data, "data.is_advertable")) {
      setSelectedProduct(get(productOne.data, "data"))
    }
    if (get(productOne.data, "data.is_advertable") === false) {
      notification.error(`${get(productOne.data, "data.title")}ga reklama berish o'chirilgan`)
    }
  }, [productOne.isFetched])

  return (
    <div className="market">
      <GoBackHeader title="Hozirda sotuvda bor mahsulotlar" />
      <Categories items={statuses} defaultItem="new" />
      <div className="mt-20">
        <Search />
      </div>

      <div className="product__cards">
        {products.isLoading
          ? Array(10)
              .fill(1)
              .map((_, i) => <Skeleton.ProductCard key={i} />)
          : productsList.map((product, i) => (
              <ProductCard
                key={get(product, "id", i)}
                product={product}
                onCreate={() => setSelectedProduct(product)}
                moreSlug="/product/1"
                setActivePostsProduct={setActivePostsProduct}
              />
            ))}
      </div>
      <Paginate
        pageCount={get(products.data, "meta.last_page")}
        setPage={(e) => setPage(e.selected)}
        meta={get(products.data, "meta")}
      />

      <Modal
        isOpen={!!link}
        maxWidth={500}
        handleClose={() => setLink()}
        title="Oqimdan nusxa olish"
        className="modal-copy-stream"
      >
        <div className="p-16">
          <div className="top">
            <h1>Oqim yaratildi !</h1>
            <p className="desc">“Nusxa olish” tugmasini bosib oqim linkidan nusxa oling</p>
          </div>
          <div className="bottom">
            <Form
              key={link}
              initialValues={{
                link,
              }}
            >
              {() => (
                <>
                  <Fields.Input name="link" label="Oqim nomi" disabled />
                  <div className="center">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        helpers.copy(link)
                      }}
                    >
                      Nusxa ko&apos;chirish
                    </button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
        <div className="adv-modal-close" onClick={() => setLink()}>
          <Icons.XIcon />
        </div>
      </Modal>

      <Modal
        isOpen={!!selectedProduct}
        className="modal-create-stream"
        maxWidth={600}
        handleClose={() => setSelectedProduct()}
      >
        <div className="top">
          <p className="title">{get(selectedProduct, "title")}</p>
          <p className="amount">
            <span className="key">To‘lov:</span>
            <div className="value c-green">
              {helpers.toReadable(get(selectedProduct, "admin_fee"))} so‘m
            </div>
          </p>
        </div>
        <div className="bottom">
          <Form
            key={get(selectedProduct, "id")}
            url="streams"
            initialValues={{
              store_id: process.env.REACT_APP_STORE_ID,
              product_id: get(selectedProduct, "id"),
              deliveryman_fee: get(selectedProduct, "deliveryman_fee") || 30000,
            }}
            onSuccess={(res) => {
              setLink(`${window.location.origin}/oqim/${get(res, "data.id")}`)
              notification.success("Oqim yaratildi !", {
                duration: 5000,
              })
              setSelectedProduct()
            }}
            fields={[
              {
                name: "name",
                required: true,
              },
              {
                name: "charity",
                submitValue: (value, values) => {
                  if (get(values, "has_charity")) return Number(value)
                  return null
                },
              },
            ]}
          >
            {({ isLoading }) => (
              <>
                <Fields.Input name="name" label="Oqim nomi" placeholder="Esda qolarli nom yozing" />
                <div className="btn-group">
                  <Button className="btn btn-submit" type="submit" loading={isLoading}>
                    Oqim yaratish
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
        <div className="adv-modal-close" onClick={() => setSelectedProduct()}>
          <Icons.XIcon />
        </div>
      </Modal>
      <VideoPost activeProduct={activePostsProduct} setActiveProduct={setActivePostsProduct} />
    </div>
  )
}

export default Market
