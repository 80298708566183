/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from "react"

import { Fancybox as NativeFancybox } from "@fancyapps/ui"
import "@fancyapps/ui/dist/fancybox/fancybox.css"

function Fancyapps(props) {
  const containerRef = useRef(null)
  const { children, delegate, options } = props

  useEffect(() => {
    const container = containerRef.current

    NativeFancybox.bind(container, delegate || "[data-fancybox]", options || {})

    return () => {
      NativeFancybox.unbind(container)
      NativeFancybox.close()
    }
  })

  return <div ref={containerRef}>{children}</div>
}

export default Fancyapps
