import qs from "qs"
import { useAtom } from "jotai"
import { Suspense, useEffect } from "react"
import { Outlet, useLocation, useSearchParams } from "react-router-dom"
import GiveCheck from "Admin/Pages/Order/components/GiveCheck"
import { Spinner } from "components"
import Sidebar from "components/Sidebar"
import { atomNav } from "services/jotai"
import { getActiveNav } from "services/helpers/navbar"

import Navbar from "./Navbar"
import "./layout.scss"

function Layout() {
  const location = useLocation()
  const [, setActiveNav] = useAtom(atomNav)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    window.scrollTo(0, 0)

    setActiveNav(getActiveNav())
  }, [location])

  useEffect(() => {
    const params = qs.parse(searchParams.toString()) || {}
    delete params.token
    setSearchParams(params)
  }, [])

  return (
    <div className="admin-layout">
      <Suspense>
        <Navbar />
      </Suspense>
      <div className="admin-page-wrapper">
        <Suspense>
          <Sidebar />
        </Suspense>
        <div className="extra-wrapper">
          <main className="page-content">
            <Suspense fallback={<Spinner />}>
              <Outlet />
            </Suspense>
          </main>
          <GiveCheck />
        </div>
      </div>
    </div>
  )
}

export default Layout
