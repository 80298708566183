import { useQueryClient } from "@tanstack/react-query"
import { get } from "lodash"

function useStore() {
  const queryClient = useQueryClient()

  const store = get(queryClient.getQueryData(["store"]), "data.data") || {}

  return store
}

export default useStore
