import { Suspense, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"

import { Spinner } from "components"
import Navbar from "./Navbar"
import Footer from "./Footer"

import "./styles.scss"

function Layout() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  return (
    <div className="front-layout">
      <Navbar />
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
      <Footer />
    </div>
  )
}

export default Layout
