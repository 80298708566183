/* eslint-disable import/no-unresolved */
import { Link } from "react-router-dom"
import { useRequest } from "hooks"
import { get } from "lodash"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "./styles.scss"
import { Icons } from "components"

function CategoryCards() {
  const categories = useRequest({
    url: "categories",
    route: "shop/v2",
    ttl: 5,
  })
  const list = get(categories.data, "data") || []

  return (
    <div className="container category-cards-container">
      <div className="category-cards">
        {categories.isLoading && (
          <Swiper spaceBetween={20} slidesPerView="auto" pagination={{ clickable: true }}>
            {Array(10)
              .fill(1)
              .map((_, i) => (
                <SwiperSlide style={{ width: "max-content" }} key={i}>
                  <div>
                    <div className="sk img"> </div>
                    <h2 className="sk" style={{ height: "20px" }}>
                      {" "}
                    </h2>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        )}
        <Swiper spaceBetween={20} slidesPerView="auto" pagination={{ clickable: true }}>
          {list.map(({ title = "", id, image = "" }, i) => (
            <SwiperSlide style={{ width: "max-content" }}>
              <Link
                to={`/products?category_id=${id}&category_title=${title}`}
                className="card"
                key={i}
              >
                {image ? (
                  <img src={image} alt="" />
                ) : (
                  <div className="img-placeholder">
                    <Icons.AdvCategory />
                  </div>
                )}
                <h2>{title}</h2>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default CategoryCards
