/* eslint-disable react/prop-types */

import { useSearchParams } from "react-router-dom"

import "./styles.scss"

function Categories({ items = [], defaultItem }) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div className="categories">
      {items.map(({ text, value }) => (
        <div
          key={text}
          className={`categories-item ${
            value === (searchParams.get("status") || defaultItem) ? "active" : ""
          }`}
          onClick={() => {
            searchParams.set("status", value)
            setSearchParams(searchParams)
          }}
        >
          {text}
        </div>
      ))}
    </div>
  )
}

export default Categories
