import api from "../api"

async function queryFn({ url, method = "get", route, urlSearchParams = {}, ttl = 0 }) {
  const options = {
    url: route ? `${route}/${url}` : url,
    method,
    params: urlSearchParams,
    cache: {
      ttl, // 60 minute.
    },
  }
  const response = await api(options)

  return response
}

export default queryFn
