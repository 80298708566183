import { Icons } from "components"
import { queryClient } from "config"
import { debounce, find } from "lodash"
import { Emitter } from "services"

const orderDebaunce = debounce((from) => {
  if (from.includes("new")) if (queryClient.isFetching() === 0) window.refetchNewOrders()
}, 700)

const setOrderPage = () => {
  Emitter.emit("SET_ORDER_PAGE", 1)
}

const orderRoles = ["owner", "operator", "main-operator"]

const navbar = [
  {
    roles: ["owner"],
    to: "/dashboard",
    label: "Dashboard",
    icon: <Icons.LifeRing className="life-ring white-icon" />,
    children: [
      {
        roles: ["owner"],
        to: "/dashboard",
        label: "Statistika",
        icon: <Icons.ChartArea />,
      },
      {
        roles: ["owner"],
        to: "/dashboard/product",
        label: "Maxsulot",
        icon: <Icons.List />,
      },
      {
        roles: ["owner"],
        to: "/dashboard/income",
        label: "Kirim",
        icon: <Icons.Calculator />,
      },
      {
        roles: ["owner"],
        to: "/dashboard/outgoing",
        label: "Chiqim",
        icon: <Icons.Calculator />,
      },
      {
        roles: ["owner"],
        to: "/dashboard/withdraw",
        label: "Pul yechish",
        icon: <Icons.Cash />,
      },
      {
        roles: ["owner"],
        to: "/dashboard/stream",
        label: "Oqim",
        icon: <Icons.ChartArea />,
      },
      {
        roles: ["owner"],
        to: "/dashboard/cancelled-income",
        label: "Atkaz qabul qilish",
        icon: <Icons.Calculator />,
      },
    ],
  },
  {
    roles: ["owner", "main-operator"],
    to: "/dashboard/user/",
    label: "Foydalanuvchilar",
    icon: <Icons.User />,
    children: [
      {
        roles: ["owner", "main-operator"],
        to: "/dashboard/user/",
        label: "Operatorlar",
        icon: <Icons.UserIcon />,
      },
      {
        roles: ["owner"],
        to: "/dashboard/user/admin",
        label: "Adminlar",
        icon: <Icons.UserIcon />,
      },
    ],
  },
  {
    roles: orderRoles,
    to: "/dashboard/order/new",
    label: "Zakaz",
    icon: <Icons.Phone className="permission-icon white-icon" />,
    children: [
      {
        roles: orderRoles,
        to: "/dashboard/order/new",
        label: "Yangi",
        icon: <Icons.List />,
        preventSearchParams: true,
        onClick: ({ from }) => {
          setOrderPage()
          orderDebaunce(from)
        },
      },
      {
        roles: orderRoles,
        to: "/dashboard/order/all",
        label: "Hammasi",
        icon: <Icons.ListOL />,
        preventSearchParams: true,
        onClick: setOrderPage,
      },
      {
        roles: orderRoles,
        to: "/dashboard/order/accepted",
        label: "Dastavkaga tayyor",
        icon: <Icons.Check />,
        preventSearchParams: true,
        onClick: setOrderPage,
      },
      {
        roles: orderRoles,
        to: "/dashboard/order/sent",
        label: "Yolda",
        icon: <Icons.Taxi />,
        preventSearchParams: true,
        onClick: setOrderPage,
      },
      {
        roles: orderRoles,
        to: "/dashboard/order/delivered",
        label: "Yetqazildi",
        icon: <Icons.Truck style={{ width: "25px" }} />,
        preventSearchParams: true,
        onClick: setOrderPage,
      },
      {
        roles: orderRoles,
        to: "/dashboard/order/pending",
        label: "Qayta qongiroq",
        icon: <Icons.Clock />,
        preventSearchParams: true,
        onClick: setOrderPage,
      },
      {
        roles: orderRoles,
        to: "/dashboard/order/cancelled",
        label: "Qaytib keldi",
        icon: (
          <Icons.XLight
            style={{ minWidth: "13px", width: "10px", marginRight: "5px", marginLeft: "2px" }}
          />
        ),
        preventSearchParams: true,
        onClick: setOrderPage,
      },
      {
        roles: orderRoles,
        to: "/dashboard/order/archived",
        label: "Arxiv",
        icon: <Icons.BoxArchive />,
        preventSearchParams: true,
        onClick: setOrderPage,
      },
      {
        roles: orderRoles,
        to: "/dashboard/order/spam",
        label: "Spam",
        icon: <Icons.Spam />,
        preventSearchParams: true,
        onClick: setOrderPage,
      },
      {
        roles: orderRoles,
        to: "/dashboard/order/hold",
        label: "Hold",
        icon: <Icons.Anchor />,
        preventSearchParams: true,
        onClick: setOrderPage,
      },
    ],
  },
  {
    roles: ["owner"],
    to: "/dashboard/finance",
    label: "Moliya",
    icon: <Icons.Ticket className="permission-icon white-icon" />,
    children: [
      {
        roles: ["owner"],
        to: "/dashboard/finance",
        label: "Pul yechish",
        icon: <Icons.Cash />,
      },
      {
        roles: ["owner"],
        to: "/dashboard/finance/sale",
        label: "Sotuv",
        icon: <Icons.Sale />,
      },
    ],
  },
]

export default navbar

export const getActiveNav = () => {
  const { pathname } = window.location
  const currentPath = pathname.split("/").slice(0, 3).join("/")

  const activeNav =
    find(navbar, (nav) => currentPath === nav.to.split("/").slice(0, 3).join("/")) || navbar[0]

  return activeNav
}
