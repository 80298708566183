import { Link } from "react-router-dom"
import "./styles.scss"

function Breadcrumb({ text }) {
  return (
    <div className="front-breadcrumb">
      <Link to="/">Bosh sahifa</Link>
      <span>/</span>
      <p>{text}</p>
    </div>
  )
}

export default Breadcrumb
